<template>
  <div>
    <div class="text__title" v-if="withTitle">{{ title }}</div>
    <div class="text__body" v-html="text"></div>

    <div class="error__container">
      <p class="error" v-if="error">{{ error | localize }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextBlock',
  props: {
    id: { type: Number, required: true },
    withTitle: { type: Boolean, default: false }
  },
  data: () => ({
    title: 'Load',
    text: null,
    error: null
  }),
  mounted () {
    this.$store.commit('loading', true)
    this.axios
      .get('texts/' + this.id)
      .then((response) => {
        this.title = response.data.title
        this.text = response.data.text
        this.$store.commit('loading', false)
      })
      .catch((e) => {
        this.$store.commit('loading', false)
        this.error = 'server_error'
        this.$scrollTo('.error__container', 300, { offset: -400 })
      })
  }
}
</script>
